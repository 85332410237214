<div class="wrapper">
  <div class="loginCard" align="center">

    <!-- mat-card *ngIf="!isOnIframe" align="center" class="registerField">
      <span>Ainda não é cadastrado?</span>
      <button mat-button class="btn-primary btn-small" style="width: 50%; justify-self: center;"
        (click)="goToRegister()">Cadastre-se grátis aqui</button>
    </mat-card -->

    <mat-card style="padding: 30px;">
      <form [formGroup]="fg">
        <div class="row">
          <div class="col-12">
            <img [src]="logo" class="imgLogo" />
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label style="font-size: 17px;">CPF</mat-label>
              <input matInput type="tel" formControlName="id" [specialCharacters]="['/', '.', '-']"
                [mask]="'000.000.000-00'" required />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>Senha</mat-label>
              <div class="d-flex flex-row justify-content-between">
                <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" required />
                <button mat-icon-button matSuffix class="show-password-btn" (click)="hidePassword = !hidePassword"
                  aria-label="Visualizar ou esconder senha">
                  <fa-icon [icon]="hidePassword ? 'eye-slash' : 'eye'"></fa-icon>
                </button>
              </div>
            </mat-form-field>
          </div>


          <div class="col-12" align="right">
            <a *ngIf="!isOnIframe" routerLink="../forgot-password"
              style="font-weight: bold; text-decoration: none; font-size: 16px;" class="blue"><u>Esqueci minha
                senha</u></a>
          </div>

          <div class="col-12 p-margin-top-3">
            <button *ngIf="!isOnIframe" mat-button class="btn-secondary btn-large" (click)="login()"
              [disabled]="loading">
              <span *ngIf="!loading; else loadingLogin">
                Entrar
              </span>

              <ng-template #loadingLogin>
                <mat-spinner [diameter]="30" color="primary"> </mat-spinner>
              </ng-template>
            </button>
          </div>
        </div>
      </form>
    </mat-card>

  </div>
</div>
